<template>
  <div class="container">
    <!-- 商品状态 -->
    <div class="item-inline-block">
      <el-radio-group v-model="page.status" @change="getGoodList()">
        <el-radio-button label="1+">全部</el-radio-button>
        <el-radio-button :label="1">已上架</el-radio-button>
        <el-radio-button :label="0">已下架</el-radio-button>
      </el-radio-group>
    </div>

    <!-- 商品类目 -->
    <!-- <div class="item-inline-block">
      <el-cascader placeholder="请选择商品分类" v-model="goodsSortSelected" :options="goodsSortList"  
        :props="{label: 'name', value: 'id'}">
      </el-cascader>
    </div> -->

    <!-- 商品类型 -->
    <div class="item-inline-block">
      <el-select v-model="page.type" @change="getGoodList()">
        <el-option label="全部" :value="1"></el-option>
        <el-option label="实物-仅展示" :value="2"></el-option>
        <el-option label="实物-可支付" :value="3"></el-option>
        <el-option label="虚拟商品" :value="4"></el-option>
      </el-select>
    </div>

    <!-- 商品搜索框 -->
    <div class="item-inline-block">
      <el-input v-model="page.name" placeholder="请输入商品名称" suffix-icon="el-icon-search" clearable
        @input="page.name == '' ? getGoodList() : ''"
        @change="getGoodList()"
        @clear="getGoodList()">
      </el-input>
    </div>

    <!-- 添加商品 -->
    <el-button @click="addGoods()" type="primary" class="el-icon-plus" style="float:right">添加商品</el-button>
    
    <!-- 商品列表 -->
    <el-table ref="multipleTable" class="table-list"
      :data="goodsList"
      :header-cell-style="{'background': '#f9f9f9', 'text-align': 'center'}"
      :cell-style="{'text-align': 'center'}">
      <!-- 选择框 -->
      <el-table-column type="selection" width="50"></el-table-column>
      <!-- 商品图片 -->
      <el-table-column label="商品图片" width="80">
        <template slot-scope="scope">
          <div class="goods-logo" :style="{'background-image': `url(${ scope.row.logo })`}"></div>
        </template>
      </el-table-column>
      <!-- 商品名称 -->
      <el-table-column prop="name" label="商品名称">
        <template slot-scope="scope"><p class="goods-name">{{ scope.row.name }}</p></template>
      </el-table-column>
      <!-- 所属分类 -->
      <!-- <el-table-column prop="sort" label="所属分类"></el-table-column> -->
      <!-- 商品类别 -->
      <el-table-column prop="sort" label="商品类别">
        <template slot-scope="scope">{{ scope.row | goodsType }}</template>
      </el-table-column>
      <!-- 商品单价 -->
      <el-table-column label="商品单价/单位">
        <template slot-scope="scope">
          <span>￥{{ scope.row.sales_price }}</span>
          <span v-if="scope.row.product_unit"> / {{ scope.row.product_unit }}</span>
        </template>
      </el-table-column>
      <!-- 创建时间/上架时间 -->
      <el-table-column label="创建时间/上架时间">
        <template slot-scope="scope">
          {{ scope.row.createTime | dateFormat('YYYY-MM-DD HH:mm:ss') }}<br>
          {{ scope.row.createTime | dateFormat('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <!-- 商品权重 -->
      <el-table-column prop="weight" label="商品权重">
        <template slot-scope="scope">
          <p class="goods-weight" @click="updateWeight(scope.row)">{{ scope.row.weight }}</p>
        </template>
      </el-table-column>
      <!-- 发布、下架、删除 -->
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <!-- 编辑 -->
          <el-tooltip class="item" effect="dark" content="编辑" placement="bottom">
            <el-button class="operation el-icon-edit-outline"
              @click="addGoods(scope.row)" type="text" size="small"></el-button>
          </el-tooltip>
          <!-- 下架 -->
          <el-tooltip v-if="scope.row.status == 1" class="item" effect="dark" content="下架" placement="bottom">
            <el-button class="operation el-icon-download"
              @click="updateStatus(scope.row, '下架')" type="text" size="small"></el-button>
          </el-tooltip>
          <!-- 上架 -->
          <el-tooltip v-if="scope.row.status == 0" class="item" effect="dark" content="上架" placement="bottom">
            <el-button class="operation el-icon-upload2"
              @click="updateStatus(scope.row, '上架')" type="text" size="small"></el-button>
          </el-tooltip>
          <!-- 删除 -->
          <el-tooltip class="item" effect="dark" content="删除" placement="bottom">
            <el-button style="color: #ff0000" class="operation el-icon-delete"
              @click="updateStatus(scope.row, '删除')" type="text" size="small"></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 20px">
      <div style="float:right;">
        <el-pagination layout="total, prev, pager, next, jumper"
          @current-change="getGoodList"
          :background="true"
          :current-page="page.pageIndex"
          :page-size="page.pageNumber"
          :total="goodsTotal">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      goodsQuery: {
        pageIndex: 1,
        pageNumber: 10,
        pageTotal: 0,
        title: '',
        status: 0,
        type: 1,
        sort: "",
      },
      goodsList:[], // 商品列表
      page: { // 查询条件
        pageIndex: 1,
        pageNumber: 6,
        status: '1+', // 商品状态
        type: 1, // 商品类型,1默认,2:实物-展示,3:实物-支付,4:虚拟
        name: ''
      },
      goodsTotal: 0, // 商品总数
      goodsSortList: [], // 商品分类列表
      goodsSortSelected: '104', // 选中的商品分类
    }
  },
  mounted(){
    this.getGoodList();
    this.queryProductSort();
  },
  methods: {
    // 获取商品列表
    async getGoodList(pageIndex){
      this.page.pageIndex = pageIndex || 1;
      let params = JSON.parse(JSON.stringify(this.page));
      let cid = this.$localStorage('ZGCMS_ACCOUNT')
      params.companyId = cid.companyId
      if(params.status == '1+') params.status  = '';
      switch(params.type){
        case 1: params.type = ""; break;
        case 2: params.type = 1; params.payStatus = 2; break;
        case 3: params.type = 1; params.payStatus = 1; break;
        case 4: params.type = 2; params.payStatus = '1,2'; break;
        default: break;
      }
      let res = await this.$ZGManager.getGoodsList(params);
      if(res.status != 200) return;
      this.goodsTotal = res.data.totalRows;
      // 将缩略图的第一张转为logo
      res.data.list.map((e) => {
        if(typeof e.thumbnail == "string"){
          try{
            let obj = JSON.parse(e.thumbnail);
            if(typeof obj == 'object' && obj ) e.logo = JSON.parse(e.thumbnail)[0];
          }catch{
            e.logo = "https://image.qll-times.com/2020/07/1bf486a77ee44e289f0488de30d3a79e.png";
          }
        }
      });
      this.goodsList = res.data.list;
    },

    // 商品编辑，删除，上架，下架
    async updateStatus(item, type){
      let status = '';
      switch(type){
        case '上架': status = 1; break;
        case '下架': status = 0; break;
        case '删除': status = -1; break;
        default: return this.$message.error("无效操作");
      }
      let dialog = await this.$confirm(`您确定要${type} “${item.name}” 吗?`, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: type == '删除' ? 'error' : 'warning'
      });
      if(dialog != 'confirm') return;
      let res; 
      if(type == '删除'){
        res = await this.$ZGManager.deleteGoods(item.id);
      }else{
        res = await this.$ZGManager.updateGoods(item.id, {status: status});
      }
      if(res.status == 200){
        this.getGoodList();
      }
    },

    // 商品修改权重
    async updateWeight(item){
      let dialog = await this.$prompt('', '请输入商品权重', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        inputValue: item.weight,
        inputPattern: /^\d{1,}$/,
        inputErrorMessage: '商品权重只能为数字'
      });
      if(dialog.action != "confirm") return;
      let res = await this.$ZGManager.updateGoods(item.id, { weight: dialog.value });
      if(res.status != 200) return;
      let index = this.goodsList.findIndex(e => e.id == item.id);
      if(index != -1){
        let data = this.goodsList[index];
        data.weight = dialog.value;
        this.goodsList.splice(index, 1, data);
      }
      this.$message.success("修改成功");
    },

    // 查询商品分类
    async queryProductSort(){
      let res = await this.$ZGManager.getParentSortList(1);
      if(res.status == 200){
        this.goodsSortList = res.data;
      }
    },

    // 添加商品
    addGoods(item){
      if(item){
        window.open(`#/shop/goods/add?id=${ item.id }`);
      }else{
        window.open(`#/shop/goods/add`);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.container{
  overflow: hidden;
}
.operation{
  font-size: 20px;
}
.table-list{
  width: 100%; 
  margin-top: 10px
}
.goods-logo{
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}
.goods-name{
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box; 
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2; 
}
.goods-weight{
  cursor: pointer;
  color: #6e87bd;
}
</style>